import React from 'react';
import {Page, Text, Image, Document, StyleSheet, View, Font} from '@react-pdf/renderer';
import TW from '../TW-Kai-98_1.ttf'
import logo from './logo_copy.jpg'
import * as uuid from "uuid"

Font.register({
    family: 'TW-Kai-98_1',
    src: TW
})
Font.registerHyphenationCallback((word) =>
  Array.from(word).flatMap((char) => [char, ''])
)

const styles = StyleSheet.create({
    con: {
        marginRight: '20px', 
        marginLeft: '15px',
        height: '100vh'
    },
    header: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
        fontFamily: 'TW-Kai-98_1',
        display: 'flex',
        width: '100%'
    },
    docImage: {
        width: '180px',
        height: '80px'
    },
    detailColumn: {
        flexDirection: 'column',
        flexGrow: 9,
        fontFamily: 'TW-Kai-98_1',
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
        fontSize: '12px',
        marginBottom: '1px'
    },
    detailRow: {
        flexDirection: 'row',
        fontFamily: 'TW-Kai-98_1',
    },
    rightColumn: {
        display: 'flex',
        color: 'black',
        textDecoration: 'none',
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
        fontFamily: 'TW-Kai-98_1',
        fontSize: '12px',
        marginTop: '2px'
    },
    leftColumn: {
        fontFamily: 'TW-Kai-98_1',
        fontSize: '12px',
        flexDirection: 'column',
        width: 170,
        marginLeft: '5px',
        marginTop: '20px',
        '@media max-width: 400': {
        width: '100%',
        paddingRight: 0,
        },
        '@media orientation: landscape': {
        width: 200,
        },
    },
    table: {
        fontFamily: 'TW-Kai-98_1',
        width: '95%',
        marginLeft: '15px',
        fontSize: '12px',
        display: 'flex'
      },
      row: {
        fontFamily: 'TW-Kai-98_1',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '12px'
      },
      tableHeader: {
        fontFamily: 'TW-Kai-98_1',
        borderTop: 'none',
        fontSize: '12px'
      },
      bold: {
        fontFamily: 'TW-Kai-98_1',
        fontWeight: 'bold',
      },
      // So Declarative and unDRY 👌
      row1: {
        fontFamily: 'TW-Kai-98_1',
        width: '10%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row2: {
        fontFamily: 'TW-Kai-98_1',
        width: '45%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row3: {
        fontFamily: 'TW-Kai-98_1',
        width: '10%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row4: {
        fontFamily: 'TW-Kai-98_1',
        width: '9%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row5: {
        fontFamily: 'TW-Kai-98_1',
        width: '13%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row6: {
        fontFamily: 'TW-Kai-98_1',
        width: '13%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      row7: {
        fontFamily: 'TW-Kai-98_1',
        width: '74%',
        borderWidth: 1,
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px'
      },
      docFooter: {
        borderTopWidth: 1,
        borderTopColor: '#112131',
        borderTopStyle: 'solid',
        fontSize: '8px',
        fontFamily: 'TW-Kai-98_1',
        marginTop: 15,
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%'
      },
  });

  const PdfFile = (props) => {
    console.log(props.total)
    console.log(props.surcharge)
    let month = new Date().getMonth()+1;
    if (month.toString().length === 1) {
        month = '0' + month;
    }

    return (
      <Document>
        <Page style={styles.body}>
            <View style={styles.con}>
                <View style={styles.header}>
                    <View style={styles.detailRow}>
                        <Image src={logo} style={styles.docImage}></Image>
                        <Text style={{marginTop: '55px', fontSize: '12px', color: '#005a00'}}>註冊非牟利團體</Text>
                    </View>
                    <View style={styles.detailColumn}>
                        <Text style={{alignSelf: 'flex-end', color: '#005a00'}}>致力推展STEM教育</Text>
                        <Text style={{alignSelf: 'flex-end', marginBottom: '2px'}}>http://stemwindows.com/</Text>
                    </View>
                </View>
                <View style={styles.rightColumn}>
                    <Text style={{alignSelf: 'flex-end'}}>Date: {new Date().toLocaleString("en-US", { day : '2-digit'})}/{month}/{new Date().getFullYear().toString().slice(-2)}</Text>
                    <Text style={{}}>Our Ref: {uuid.v4()}</Text>
                </View>
                <View style={styles.leftColumn}>
                    <Text>{props.company}</Text>
                    <Text>{props.address}</Text>
                    <Text>{props.name}</Text>
                </View>
                <View style={{fontFamily: 'TW-Kai-98_1', display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '20px', fontSize: '20px'}}>
                    <Text>報價單</Text>
                    <Text>QUOTATION</Text>
                </View>
                <View>
                    <View style={styles.table}>
                        <View style={[styles.row, styles.bold, styles.tableHeader]}>
                            <Text style={styles.row1}>項目</Text>
                            <Text style={styles.row2}>貨品</Text>
                            <Text style={styles.row3}>備註</Text>
                            <Text style={styles.row4}>數量</Text>
                            <Text style={styles.row5}>單價</Text>
                            <Text style={styles.row6}>金額</Text>
                        </View>
                        {props.productList.map((productList, i) => (
                            <View key={i} style={styles.row} wrap={false}>
                                <Text style={styles.row1}>{i+1}</Text>
                                <Text style={styles.row2}>{productList.link}</Text>
                                <Text style={styles.row3}>{productList.option ? (productList.option) : ('-')}</Text>
                                <Text style={styles.row4}>{productList.quantity}</Text>
                                <Text style={styles.row5}>${productList.price.toFixed(2)}</Text>
                                <Text style={styles.row6}>${(productList.price*productList.quantity).toFixed(2)}</Text>
                            </View>
                        ))}
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <Text style={styles.row7}>服務費</Text>
                            <Text style={styles.row5}>HK$</Text>
                            <Text style={styles.row6}>{props.surcharge === 90 ? ((90).toFixed(2)) : ((props.total*props.surcharge).toFixed(2))}</Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <Text style={styles.row7}>總額</Text>
                            <Text style={styles.row5}>HK$</Text>
                            <Text style={styles.row6}>{props.surcharge === 90 ? ((props.total + 90).toFixed(2)) : ((props.surcharge*props.total)+props.total).toFixed(2)}</Text>
                        </View>
                    </View>
                    <View style={{fontFamily: 'TW-Kai-98_1', fontSize: '12px', marginLeft: '15px', marginRight: '2px', marginTop: '2px'}}>
                        <Text>備註</Text>
                        <Text>以上是自動報價，並不構成合約，若貴公司認為自動報價的價格合適，請把以上「Our Ref」編號電郵至stemwindows@gmail.com，本公司將跟進處理，並將發出正式報價</Text>
                    </View>
                    <View style={{fontFamily: 'TW-Kai-98_1', alignSelf: 'flex-end', justifySelf: 'flex-end', marginTop: '100px', fontSize: '12px'}}>
                        <Text>經手人：林小姐</Text>
                    </View>
                    <View style={styles.docFooter}>
                        <View style={{marginTop: '2px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}>
                            <Text>新界火炭禾香街1-7號華威工業大廈9字樓C座908室</Text>
                            <Text>電話：(852) 5660 1727</Text>
                            <Text>電郵：stemwindows@gmail.com</Text>
                        </View>
                    </View>
                </View>
            </View>
        </Page>
      </Document>
    );
  };
  
  export default PdfFile;