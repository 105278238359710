import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Form from './component/Form';
import Download from './component/Download';

function App() {
  return (
    <>
    <BrowserRouter>
			<Routes>
				<Route index element={<Form />} />
        <Route path="/download" element={<Download />}/>
      </Routes>
    </BrowserRouter> 
  </>
  );
}

export default App;
