import * as React from 'react';
import {useState} from 'react';
import ProductGrid from './GridActionsCell';
import TextField from '@mui/material/TextField';
import CountrySelect from './CountrySelect';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

const Form = () => {

    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [name, setName] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [error, setError] = useState(false);
    const [formError, setFormError] = useState(false);
    const [productList, setProductList] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    const navigate = useNavigate();

    document.body.style.background = '#26364E';

    const checkPhoneInput = (e) => {
        const result = e.target.value.replace(/\D/g, '');
        setPhone(result);  
    }

    const handleSubmit = () => {
        setSubmitted(true);
        if (company === '') {
            setError(true);
            setErrorMsg('請輸入公司名稱')
        }
        else if (address === '') {
            setError(true);
            setErrorMsg('請輸入公司地址')
        }
        else if (name === '') {
            setError(true);
            setErrorMsg('請輸入聯絡人姓名')
        }
        // else if (phone === '') {
        //     setError(true);
        //     setErrorMsg('請輸入聯絡人電話')
        // } 
        else if (formError === true) {
            setError(true);
            setSubmitted(false);
        } 
        else {
            setError(false);
            setErrorMsg('');
            SubmitForm();
        }
    }

    const Totalprice = () => {
        let total = 0;
        for (let i = 0; i < productList.length; i++) {
            total = total + productList[i].price*productList[i].quantity
        }
        return total
    }

    const surcharge = () => {
        const total = Totalprice()
        if (total <= 500){
            return 90
        } else if (total > 500 && total < 1999){
            return 0.16
        } else if (total >= 2000 && total < 4000){
            return 0.14
        } else if (total >= 4000 && total < 6000){
            return 0.12
        } else {
            return 0.1
        }
    }

    const SubmitForm = () => {
        console.log(Totalprice())
        const payload = {
            company: company,
            address: address,
            name: name,
            productList: productList,
            total: Totalprice(),
            surcharge: surcharge()
        }

        navigate('/download', {state: payload})

    }

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleCompanyChange = (e) => {
        setCompany(e.target.value);
    }

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    }

   return(
    <>
    {error ? (
        <>
        <Alert severity="error" style={{width: 'auto'}}>
            <AlertTitle>錯誤</AlertTitle>
            {errorMsg}
        </Alert>
      </>
    ) : (
        <></>
    )}
    <div style={{display: 'flex', justifyContent: 'center'}}>
        <form style={{marginLeft: '10px', marginTop: '30px', marginBottom: '30px', marginRight: '10px', width: '80%', backgroundColor: 'white', padding: '30px', borderRadius: '30px' }}>
            
            <div style={{display: 'flex', alignItems: 'center', fontSize: 20, width: '100%'}}>
                <img style={{width: '150px'}} src="/logo.jpg" />
                STEM Windows自動報價系統
            </div>
            <div>
                <div style={{marginTop: '10px', marginBottom: '10px'}}>公司名稱</div>
                <TextField
                id="standard-input"
                variant="outlined"
                defaultValue=""
                onChange={handleCompanyChange}
                style={{width: '100%'}}
                />
            </div>
            <div style={{}}>
                <div style={{marginTop: '18px', marginBottom: '10px'}}>公司地址</div>
                <TextField
                id="standard-input"
                variant="outlined"
                defaultValue=""
                onChange={handleAddressChange}
                style={{width: '100%'}}
                />
            </div>
            <div>
                <div style={{marginTop: '18px',  marginBottom: '10px'}}>聯絡人姓名</div>
                <TextField
                id="standard-input"
                variant="outlined"
                defaultValue=""
                onChange={handleNameChange}
                style={{width: '100%', marginBottom: '18px'}}
                />
            </div>
            {/* <div style={{marginTop: '18px', width: '49%', marginBottom: '20px'}}>
                <div style={{marginBottom: '10px'}}>聯絡人電話</div>
                <div style={{display:'flex', flexDirection: 'row', width: '100%'}}>
                    <CountrySelect style={{width: '30%', minWidth: '300px'}}/>
                    <TextField
                    id="standard-number"
                    variant="outlined"
                    defaultValue="+852 "
                    onChange={(e) => checkPhoneInput(e)}
                    value={phone}
                    style={{width: '100%'}}
                    />
                </div>
            </div>  */}

            <ProductGrid error={setFormError} errorMsg={setErrorMsg} productList={setProductList} submitted={submitted}/>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button style={{marginTop: '15px', backgroundColor: '#26364E', width: '20%', minWidth: '300px'}}variant="contained" onClick={handleSubmit}>提交</Button>
            </div>
        </form> 
    </div>
   </>
   )
}
export default Form