import * as React from 'react';

const Footer = () => {
    return(
    <>
        <div style={{width: '100%', backgroundColor: '#9897A9', paddingBottom: '10px'}}>
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <div>電郵：stemwindows@gmail.com</div>
                <div>電話：(852) 5660 1727</div>
                <div>傳真：(852)3020 9610</div>
                <div>新界火炭禾香街1-7號華威工業大廈9字樓C座908室</div>
            </div>
        </div>
    </>)
}
export default Footer